import React from "react"
import { StaticQuery, graphql } from "gatsby"

const Lessons = ({data}) => (
  data?.allMarkdownRemark?.edges.map(edge => {
    const {html, frontmatter: {slug, title}} = edge?.node;
    return (
      <>
        <h2><a href={slug}>{title}</a></h2>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </>
    )
  })
)

export default function LessonsList() {
  return (
    <StaticQuery
      query={lessonsQuery}
      render={data => <Lessons data={data} />}
    />
  )
}

const lessonsQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___slug] }) {
      edges {
        node {
          id
          html
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
          }
        }
      }
    }
  }
`