import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LessonsList from "../components/lessons-list"

const LessonsPage = ({location}) => (
  <Layout location={location}>
    <SEO title="Lessons" />
    <h1>GEMS Lessons</h1>
    <LessonsList />
  </Layout>
)

export default LessonsPage;
